<template>
  <div class="body-box" v-track="{ event: 'browse', route: $route, isButton: false }">
    <div class="main-content">
      <Row :gutter="20" index="">
        <Col span="24">
        <Form ref="searchParam" :model="searchParam" label-position="right" :label-width="70" inline>
          <FormItem label="房屋地址" :label-width="70" style="margin-right: 28px;">
            <Input type="text" maxlength="50" style="width: 200px;" v-model="searchParam.houseAddress" placeholder="请输入">
            </Input>
          </FormItem>
          <FormItem label="小区名称" :label-width="70" style="margin-right: 28px;">
            <Input type="text" maxlength="50" style="width: 200px;" v-model="searchParam.lpName" placeholder="请输入">
            </Input>
          </FormItem>
          <FormItem label="任务名称" style="margin-right: 28px;">
            <Select clearable multiple :max-tag-count="1" v-model="searchParam.taskIds" style="width: 200px;">
              <Option v-for="item in taskIdsList" :key="item.id" :value="item.id">
                {{ item.taskName }}
              </Option>
            </Select>
          </FormItem>
          <FormItem label="估价基准日" :label-width="85" style="margin-right: 28px;">
            <DatePicker style="width: 200px;" :transfer="true" :options="dataLimit2" format="yyyy-MM-dd" type="daterange"
              placement="bottom-end" @on-change='getevaluateBasicTimeSelectDate' placeholder="请选择"></DatePicker>
          </FormItem>
          <FormItem label="提交时间">
            <DatePicker style="width: 200px;" :transfer="true" :options="dataLimit2" format="yyyy-MM-dd" type="daterange"
              placement="bottom-end" @on-change='getCreateTimeSelectDate' placeholder="请选择"></DatePicker>
          </FormItem>
          <FormItem label="出值状态" style="margin-right: 28px;">
            <Select clearable v-model="searchParam.showUserResult" style="width: 200px;">
              <Option v-for="item in showUserResultList" :key="item.value" :value="item.value">
                {{ item.label }}
              </Option>
            </Select>
          </FormItem>
          <FormItem label="城市" :label-width="70" style="margin-right: 28px;">
            <Select v-model="searchParam.cityCode" clearable style="width: 200px;">
              <Option v-for="item in cityList" :value="item.cityCode" :key="item.cityCode">
                {{ item.cityName }}
              </Option>
            </Select>
          </FormItem>
          <FormItem :label-width="12">
            <Button type="primary " @click="search">查询</Button>
          </FormItem>
        </Form>
        </Col>

      </Row>
      <Row :gutter="6" style="margin-bottom: 20px;display: flex ; justify-content: flex-end ;">
        <Button type="primary" @click="downResult" :disabled="downResultDisabled">下载结果 </Button>

      </Row>
      <!-- <div  class="in-process-box">
       <div v-loading="loading" class="loading-box"></div>
       <div>处理中</div>
      </div> -->
      <Row :gutter="20">

        <Col :span="24">
        <Table :columns="columns" :data="tableData" :no-data-text="noTableText" v-loading="isLoading">
          <template slot-scope="{ row }" slot="houseAddress">
            <Tooltip max-width="400" :transfer="true">
              <div class="houseAddress-box" @click="goResult(row)">
                <div class="red-point" v-if="row.hasRedPoint"></div>
                {{ row.houseAddress | filter_houseAddress }}
              </div>
              <div slot="content">
                <div class="">{{ row.houseAddress }}</div>
              </div>
            </Tooltip>
          </template>
          <template #action="{ row, index }">
            <el-button type="text" @click="handleDel(index, row)">删除</el-button>
          </template>

        </Table>
        </Col>
      </Row>
      <Row :gutter="16" style="margin-top: 24px;" type="flex" justify="flex-start">
        <el-pagination :current-page="pageable.current" :page-size="pageable.size" :hide-on-single-page="isShowPage"
          background layout="prev, pager, next, jumper" :total="pageable.total" @size-change="handleSizeChange"
          @current-change="handleCurrentChange" />
      </Row>
    </div>

  </div>
</template>

<script  setup>
import { reactive, ref, computed, getCurrentInstance } from "vue"
import { batchEvaluationHistoryPage, getEvlHisCity, delHistoryPage, downLoadExcel, userBatchTaskManagement } from '@/api/pledge'
import {
  formatTime,
  formatDate,
  _debounce
} from "@/utils/";
import { toCurrency } from "@/utils/";
import { dataReports } from '@/hooks/useDataReports';

import { useTable } from '@/hooks/useTable';
import { useHandleData } from '@/hooks/useHandleData';
import { useStore, useRouter, useRoute } from "@/utils/vueApi.js";
import util from "@/utils/utils2";
import {
  downloadData,
  formatJson
} from "@/utils/index";
import {
  rwlb,
} from "@/api/pledge";
const { proxy } = getCurrentInstance()


const dataLimit2 = {
  disabledDate(date) {
    return (
      date.valueOf() > Date.now()
    );
  },
}

const cityList = ref([])

const getCityList = async () => {
  let params = {
    isBatch: 1 //0:单个估价 1:批量估价
  }
  let res = await getEvlHisCity(params)
  cityList.value = res
}
getCityList()
dataReports()
// 获取任务列表
const taskIdsList = ref([])
const getTaskList = async () => {
  let params = {
    current: 1,
    state: "1",
    size: 999999,
  }
  let data = await userBatchTaskManagement(params)
  taskIdsList.value = data.records
}
getTaskList()

const downResultDisabled = ref(false)
//下载结果
async function downResult() {
  downResultDisabled.value = true
  setTimeout(() => {
    downResultDisabled.value = false
  }, 1000);
  const loading = proxy.$loading({
    lock: true,
    text: '准备下载...',
  });
  // setTimeout(() => {
  //   loading.close();
  // }, 1000);

  let params = {
    ...searchParam.value,
    ...initParam
  }
  downLoadExcel(params).then((res) => {
    loading.close();

    if (res.data.type === 'application/json') {
      const reader = new FileReader()
      reader.readAsText(res.data)
      reader.onload = () => {
        //json字符串转json对象
        const resText = JSON.parse(reader.result)
        if (resText.code === -1) {
          proxy.$msg.error({ text: resText.data })
          return
        }
      }
    } else {
      if (res.hasOwnProperty("headers")) {
        //调用封装好的下载方法
        proxy.$msg.success({ text: '文件正在下载中，详情请看下载列表', duration: 3000 });

        util.downloadBlod(res);
      } else {
        //将错误信息返回
        let blob = new Blob([res]);
        blob.text().then((res) => {

        });
      }
    }


  })
}

const loading = ref(true);
//--------------查询条件-----------------
const initParam = {}
const showUserResultList = ref([
  { value: 1, label: '已出值' },
  { value: 2, label: '暂无法估价' },
])
const getCreateTimeSelectDate = (val) => {
  initParam.createTimeStart = val[0];
  initParam.createTimeEnd = val[1];
}
const getevaluateBasicTimeSelectDate = (val) => {
  initParam.evaluateBasicDateStart = val[0];
  initParam.evaluateBasicDateEnd = val[1];
}
//--------------表格相关-----------------
const emptyImg = require("@/assets/image/task/empty.png");
const router = useRouter();

const goResult = (row) => {
  router.push({
    path: '/Empowerment/pledge/pledgeManage/taskResult',
    query:
    {
      cityCode: row.cityCode,
      sampleHistoryId: row.historyId,
      routeName: 'taskRecord',
    }
  })
}
//表头
const columns = ref([
  {
    title: '序号',
    key: "index",
    width: 100,
    fixed: 'left',
  },
  {
    title: '城市',
    key: "cityFullName",
    width: 100,
    fixed: 'left',
  },
  {
    title: '房屋地址',
    key: 'houseAddress',
    width: 300,
    tooltip: true,
    slot: 'houseAddress',
    fixed: 'left',

  },
  {
    title: '小区名称',
    key: 'lpName',
    width: 160,
    tooltip: true
  },
  {
    title: '面积',
    key: 'houseArea',
    width: 160,
    tooltip: true
  },
  {
    title: '单价（元/㎡） ',
    key: "evaluateUnitPrice",
    width: 160,
    render: (h, params) => {
      let text = ''
      switch (params.row.assessResult) {
        case 1:
          text = params.row.evaluateUnitPrice
          break;
        case 3:
          text = params.row.evaluateUnitPrice + '(' + '参考价' + ')'
          break;
        default:
          text = params.row.evaluateUnitPrice
          break;
      }
      return h(
        "span",
        text
      );
    },
  },

  {
    title: "总价（万元）",
    key: "evaluateTotalPrice",
    width: 160,
    render: (h, params) => {
      let text = ''
      switch (params.row.assessResult) {
        case 1:
          text = params.row.evaluateTotalPrice
          break;
        case 3:
          text = params.row.evaluateTotalPrice + '(' + '参考价' + ')'
          break;
        default:
          text = params.row.evaluateTotalPrice
          break;
      }
      return h(
        "span",
        text
      );
    },
  },
  {
    title: '出值状态',
    key: 'showUserResult',
    width: 160,
    render: (h, params) => {

      let text = ''
      switch (params.row.showUserResult) {
        case '1':
          text = '已出值'
          break;
        case '2':
          text = '暂无法估价'
          break;
        default:
          text = ''
          break;
      }
      return h(
        "span",
        text
      );
    },
  },
  {
    title: '估价基准日',
    key: 'evaluateBasicDate',
    width: 160,
    tooltip: true
  },
  {
    title: '任务名称',
    key: 'taskName',
    width: 170,
    tooltip: true

  },

  {
    title: "提交时间",
    key: "taskCreateTime",
    minWidth: 200,
    // width: 200,
    render: (h, params) => {
      return h(
        "span",
        params.row.taskCreateTime ? formatTime(params.row.taskCreateTime, '/') : formatTime(
          params.row.gmtUpdated, '/')
      );
    },
  },

  // {
  //   title: "完成时间",
  //   key: "createTime",
  //   width: 200,

  //   render: (h, params) => {
  //     return h(
  //       "span",
  //       params.row.createTime ? formatTime(params.row.createTime, '/') : formatTime(
  //         params.row.gmtUpdated, '/')
  //     );
  //   },
  // },
  // {
  //   title: '操作',
  //   slot: 'action',
  //   width: 100,
  //   fixed: 'right',
  // },
])
const dataCallBack = (data) => {
  data.records.forEach((item, index) => {
    if (item.evaluateUnitPrice === '' || item.evaluateUnitPrice == 0) {
      item.evaluateUnitPrice = '--'
    } else {
      item.evaluateUnitPrice = (Number(item.evaluateUnitPrice) * 1).toFixed(0)
      item.evaluateUnitPrice = toCurrency(item.evaluateUnitPrice)
    }
    if (item.evaluateTotalPrice === '' || item.evaluateTotalPrice == 0) {
      item.evaluateTotalPrice = '--'
    } else {
      item.evaluateTotalPrice = (Number(item.evaluateTotalPrice) * 1).toFixed(2)
      item.evaluateTotalPrice = toCurrency(item.evaluateTotalPrice)
    }

    return item
  });
  return data;
}
const {
  tableData,
  pageable,
  searchParam,
  searchInitParam,
  isShowPage,
  getTableList,
  search,
  reset,
  handleSizeChange,
  handleCurrentChange,
  isLoading,
} = useTable(batchEvaluationHistoryPage, initParam, true, dataCallBack);
const noTableText = computed(() => {
  if (isLoading.value) {
    return '加载中...';
  } else {
    return '暂无数据';
  }
});

//删除
const handleDel = async (index, row) => {

  const params = {
    historyId: row.historyId,
  };

  await useHandleData(delHistoryPage, params, '确定删除？');
  //   const params = {
  //  current:1,
  //   size:10,
  // };
  // await useHandleData(batchEvaluationHistoryPage, params, '删除该估价记录');
  search();
  // getPageData();
};



</script>

<style lang="scss" scoped>
.in-process-box {
  width: 56px;
  height: 56px;
  border-radius: 11.2px;
  opacity: 1;

  background: #ECF5FF;

}

.body-box {
  background: #EFF1F6;
  // height: 100%;
  min-height: 100%;
  padding: 24px;
  min-width: 1200px;

  .main-content {
    background: #FFFFFF;
    height: 100%;
    border-radius: 10px 10px 10px 10px;
    padding: 24px;

    min-height: 844px;
    // margin: 24px;
  }

  .houseAddress-box {
    font-family: PingFangSC-Regular-, PingFangSC-Regular;
    font-weight: normal;
    color: #0077FF;
    cursor: pointer;
    width: 275px;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;

    .red-point {
      background: red;
      width: 10px;
      height: 10px;
      border-radius: 50%;
      float: left;
      position: absolute;
      left: -13px;
      top: 5px;

    }
  }
}

// /deep/ .ivu-select-multiple .ivu-select-selection div {
//   overflow: hidden;
//   white-space: nowrap;
// }
/deep/ .ivu-select-multiple .ivu-tag {
  max-width: 67%;
}
</style>
